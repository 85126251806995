<template>
  <div>
    <span v-if="debugMode" class="kiosk-info">
      <div>
        <span v-if="kiosk">Name: {{ kiosk.name }} ({{ kiosk._id }}</span> |
        <span v-if="asset">Asset: {{ asset.siteName }} ({{ kiosk.assetId }}</span>
      </div>
      <div>
        <span v-if="debug.lastRefresh">Last refresh (kiosk): {{ debug.lastRefresh }} ({{ debug.lastRefreshDuration }}s)</span> |
        <span v-if="debug.lastAssetRefresh">Last refresh (asset): {{ debug.lastAssetRefresh }} ({{ debug.lastAssetRefreshDuration }}s)</span>
      </div>
    </span>
    <span v-if="!isOnline" class="kiosk-error text-danger">
      <span class="text-danger font-size-h1">No internet connection. If this problem persists please contact Etainabl support.</span></span
    >
    <div v-if="kiosk._id" :key="kiosk._id">
      <router-view />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'KioskView',
  data() {
    return {
      debugMode: false,
      interval: null,
      isOnline: true,
      onlineTimeout: null
    };
  },
  computed: {
    ...mapGetters({
      kiosk: 'kiosk/kiosk',
      asset: 'kiosk/asset',
      debug: 'kiosk/debug',
      slideIndex: 'kiosk/slideIndex',
      slides: 'kiosk/slides'
    })
  },
  beforeDestroy() {
    clearInterval(this.interval);

    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  },
  async mounted() {
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';

    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);

    await this.refresh();

    this.setLoading(false);

    if (this.$route.query.debug === '1') {
      this.debugMode = true;
    }

    this.interval = setInterval(() => {
      this.refresh();
    }, 15 * 60 * 1000);
  },
  methods: {
    ...mapActions({
      getKiosk: 'kiosk/get'
    }),
    ...mapMutations({
      setDebug: 'kiosk/SET_DEBUG',
      setLoading: 'kiosk/SET_LOADING'
    }),
    async refresh() {
      const refreshTimer = new Date().getTime();

      // Kiosk-level data
      await this.getKiosk({
        id: this.$auth.user.kioskId,
        data: {
          params: {
            $populate: 'entity'
          }
        }
      });

      this.setDebug({
        lastRefresh: moment().format('DD/MM/YYYY HH:mm:ss'),
        lastRefreshDuration: ((new Date().getTime() - refreshTimer) / 1000).toFixed(2)
      });
    },
    updateOnlineStatus(e) {
      const { type } = e;

      console.log(type);

      const isOnline = type === 'online';

      if (!isOnline) {
        this.onlineTimeout = setTimeout(() => {
          this.isOnline = isOnline;
        }, 1000);
      } else {
        clearTimeout(this.onlineTimeout);
        this.isOnline = isOnline;
      }
    }
  }
};
</script>
<style lang="scss">
$primary: #ffffff;
$secondary: #4d9575;
$faded: #82979a;
$font: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, Noto Sans, sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

.kiosk-info {
  position: fixed;
  text-align: center;
  color: #fff;
  background: #3c3c3c;
  padding: 0 10px;
  width: 100%;
  z-index: 1000;
  opacity: 0.9;
}
.kiosk-error {
  position: fixed;
  bottom: 0;
  text-align: center;
  background: #3c3c3c;
  padding: 0 20px;
  width: 100%;
  z-index: 1000;
  opacity: 0.9;
}

.kiosk-card {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.25) !important;
  padding: 1.35rem;
  transition: all 0.75s ease;

  &--title {
    color: #fff;
    font-weight: 300;
    line-height: 1.25;

    padding-bottom: 1rem;
    margin-bottom: 1.35rem;
    border-bottom: 3px solid rgba(255, 255, 255, 0.5);

    small {
      font-size: 1.4rem;
      color: rgba(255, 255, 255, 0.75);
    }
  }

  h1.kiosk-card--title {
    font-size: 3rem;
  }

  h2.kiosk-card--title {
    font-size: 2.5rem;
  }

  &--display {
    color: #fff;
    font-weight: 300;
    line-height: 1.25;
    font-size: 2.75rem;
  }

  .border-bottom {
    border-bottom: 3px solid rgba(255, 255, 255, 0.5) !important;
  }
}

.kiosk-graph {
  .kiosk-card--title {
    text-align: center;
    margin-bottom: 3rem;
    border-bottom: 0;
    margin-bottom: 0;
  }
}

.kiosk-chart {
  .highcharts-background {
    fill: transparent;
  }

  .highcharts-title {
    font-family: $font;
    fill: $primary;
    font-weight: bold;
  }

  .highcharts-data-label text {
    .highcharts-strong {
      font-weight: 400;
    }
  }

  .highcharts-data-label-connector {
    fill: none !important;
  }
  .highcharts-axis-labels {
    font-family: $font;

    fill: $primary !important;
    font-size: 24px !important;
  }

  .highcharts-xaxis-labels > text,
  .highcharts-yaxis-labels > text {
    font-family: $font;
    fill: $primary !important;
    font-size: 28px !important;
  }

  .highcharts-axis-title,
  .highcharts-legend-item > text {
    font-family: $font !important;

    fill: $primary !important;
    font-size: 24px !important;
    font-weight: 600 !important;
    stroke: transparent !important;
  }
}
</style>
