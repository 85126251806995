import actions from './actions';
import mutations from './mutations';
import { crudActions } from '../../lib/commonActions';

const store = {
  namespaced: true,
  state: {
    loading: true,
    assetLoading: true,
    total: 0,
    loadingAction: {
      ...Object.keys(crudActions).reduce((obj, action) => {
        return { ...obj, [action]: false };
      }, {}),
      getAsset: false,
      getWeather: false,
      getNews: false,
      getAssetSmartDataConsumption: false,
      getAssetReadingConsumption: false,
      getAssetInvoiceConsumption: false,
      getAssetEmissions: false
    },
    errorAction: {
      ...Object.keys(crudActions).reduce((obj, action) => {
        return { ...obj, [action]: false };
      }, {}),
      getAsset: false,
      getWeather: false,
      getNews: false,
      getAssetSmartDataConsumption: false,
      getAssetReadingConsumption: false,
      getAssetInvoiceConsumption: false,
      getAssetEmissions: false
    },
    debug: {},
    slideIndex: 0,
    slides: [
      {
        title: 'Leaderboard',
        subTitle: 'See how your floor is doing compared to others!',
        component: 'KioskEnergyLeaderboard'
      },
      {
        title: 'Consumption',
        subTitle: "This building's energy consumption",
        component: 'KioskHHGraph'
      },
      {
        title: 'Benchmark',
        subTitle: 'How this building compares to others',
        component: 'KioskBenchmark'
      },
      {
        title: 'News',
        subTitle: 'News, weather and travel for your area',
        component: 'KioskLocalNews'
      },
      {
        title: 'Energy Usage',
        subTitle: 'Recent electricity consumption of this building',
        component: 'KioskEnergy'
      },
      {
        title: 'Carbon',
        subTitle: "This building's CO2 emission data",
        component: 'KioskCarbon'
      }
    ],
    kiosk: {},
    asset: {},
    news: {
      local: {},
      travel: {},
      weather: {
        current: {},
        daily: []
      }
    },
    assetConsumption: {
      smartData: {},
      reading: {},
      invoice: {}
    },
    assetEmissions: {},
    accounts: {}
  },
  getters: {
    loading: state => state.loading,
    assetLoading: state => state.assetLoading,
    loadingAction: state => state.loadingAction,
    errorAction: state => state.errorAction,

    slideIndex: state => state.slideIndex,
    slides: state => state.slides,

    kiosk: state => state.kiosk,
    asset: state => state.asset,
    accounts: state => state.accounts,
    news: state => state.news,
    assetConsumption: state => state.assetConsumption,
    assetEmissions: state => state.assetEmissions,
    debug: state => state.debug
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  }
};

export default store;
