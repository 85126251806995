import Vue from 'vue';
import { crudActions, fileActions } from './commonActions';

const hyphenCaseToCamelCase = str => {
  return str.replace(/-([a-z])/g, g => {
    return g[1].toUpperCase();
  });
};

const hyphenCaseToTitleCase = str => {
  const body = hyphenCaseToCamelCase(str);

  const head = str.charAt(0).toUpperCase();

  return `${head}${body.slice(1)}`;
};

export default (resource, subResource = {}, options = { fileActions: false, updateSubDocument: false }) => {
  const actions = { ...crudActions, ...(options.fileActions ? fileActions : {}) };

  const mutations = {};

  Object.keys(actions).forEach(crudAction => {
    const mutationPrefix = `${crudAction.toUpperCase()}_${(subResource.singular || resource.singular).replace(/-/g, '_').toUpperCase()}_`;

    const action = subResource.plural ? `${crudAction}${hyphenCaseToTitleCase(subResource.plural)}` : crudAction;

    mutations[`${mutationPrefix}REQUEST`] = state => {
      state.loading = true;
      state.loadingAction[action] = true;
      state.errorAction[action] = false;
      state.validationErrors = {};
    };
    mutations[`${mutationPrefix}SUCCESS`] = (state, body, skipState = false) => {
      state.loading = false;
      state.loadingAction[action] = false;
      state.errorAction[action] = false;
      state.validationErrors = {};

      if (body && !skipState) {
        if (action.startsWith('list')) {
          if (subResource.plural && options.updateSubDocument) {
            // Handle sub-resource e.g. state.account.creditNotes
            Vue.set(
              state[hyphenCaseToCamelCase(resource.singular)],
              hyphenCaseToCamelCase(subResource.plural),
              body.data && Array.isArray(body.data) ? body.data : body
            );
          } else {
            // Handle resource e.g. state.accounts
            if (typeof state[hyphenCaseToCamelCase(resource.plural)] !== 'undefined') state[hyphenCaseToCamelCase(resource.plural)] = body.data;
            state.limit = body.limit;
            state.total = body.total;
            state.skip = body.skip;
          }
        } else if (action === 'schema') {
          if (state.schema !== 'undefined') state.schema = body;
        } else if (action === 'search') {
          state.searchResults = body;
        } else {
          if (!subResource.singular) {
            // Handle resource
            if (typeof state[hyphenCaseToCamelCase(resource.singular)] !== 'undefined') state[hyphenCaseToCamelCase(resource.singular)] = body;
          }
        }
      }
    };
    mutations[`${mutationPrefix}ERROR`] = (state, error) => {
      state.loading = false;
      state.loadingAction[action] = false;
      state.errorAction[action] = error.body;
      if (error.body?.formMessages) state.validationErrors = error.body.formMessages;
    };
  });

  mutations[`CLEAR_${(subResource.singular || resource.singular).replace(/-/g, '_').toUpperCase()}`] = state => {
    state.loading = false;
    state.total = 0;

    if (subResource.plural) {
      if (typeof state[resource.singular][subResource.singular] !== 'undefined') Vue.set(state[resource.singular], subResource.singular, {});
      if (typeof state[resource.plural][subResource.plural] !== 'undefined') Vue.set(state[resource.plural], subResource.plural, []);
    } else {
      if (typeof state[resource.singular] !== 'undefined') state[resource.singular] = {};
      if (typeof state[resource.plural] !== 'undefined') state[resource.plural] = [];
    }
  };

  mutations['SET_VALIDATION_ERRORS'] = (state, errors) => {
    state.validationErrors = errors;
  };

  mutations['CLEAR_VALIDATION_ERRORS'] = (state, key) => {
    if (key) {
      Vue.delete(state.validationErrors, key);
    } else {
      state.validationErrors = {};
    }
  };

  return mutations;
};
