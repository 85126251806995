<template>
  <div>
    <KioskView />
  </div>
</template>

<script>
import KioskView from './Kiosk/KioskView';

export default {
  name: 'KioskWrapper',
  components: {
    KioskView
  }
};
</script>
