export default [
  {
    path: '/companies/create',
    name: 'company-create',
    component: () => import('./CompanyCreate'),
    meta: { permissions: ['admin'] }
  },

  // User View
  {
    path: '/company',
    component: () => import('./CompanyView'),
    meta: { permissions: ['company_admin'] },
    children: [
      {
        path: '',
        name: 'company-view',
        component: () => import('./CompanyEdit')
      },
      {
        path: 'users',
        meta: { subTitle: 'User Management' },
        component: () => import('./users/CompanyUsers'),
        children: [
          {
            path: '',
            name: 'company-users-list',
            meta: { permissions: ['company_owner', 'company_user', 'company_user_full'] },
            component: () => import('./users/CompanyUsersList')
          },
          {
            path: 'create',
            name: 'company-users-create',
            meta: { subTitle: 'User Management' },
            component: () => import('./users/CompanyUsersCreate')
          },
          {
            path: ':userId/edit',
            name: 'company-users-edit',
            meta: { subTitle: 'User Management' },
            component: () => import('./users/CompanyUsersEdit')
          }
        ]
      },
      {
        path: 'roles',
        meta: { subTitle: 'Role Management' },
        component: () => import('./roles/CompanyRoles'),
        children: [
          {
            path: '',
            name: 'company-roles-list',
            meta: { subTitle: 'Role Management' },
            component: () => import('./roles/CompanyRolesList')
          },
          {
            path: 'create',
            name: 'company-roles-create',
            meta: { subTitle: 'Role Management' },
            component: () => import('./roles/CompanyRolesManage')
          },
          {
            path: ':roleId/edit',
            name: 'company-roles-edit',
            meta: { subTitle: 'Role Management' },
            component: () => import('./roles/CompanyRolesManage')
          }
        ]
      },
      {
        path: 'permissions',
        meta: { subTitle: 'Permission Management' },
        component: () => import('./permissions/CompanyPermissions'),
        children: [
          {
            path: '',
            name: 'company-permissions-list',
            meta: { subTitle: 'Permission Management' },
            component: () => import('./permissions/CompanyPermissionsList')
          },
          {
            path: 'create',
            name: 'company-permissions-create',
            meta: { subTitle: 'Permission Management' },
            component: () => import('./permissions/CompanyPermissionsManage')
          },
          {
            path: ':permissionId/edit',
            name: 'company-permissions-edit',
            meta: { subTitle: 'Permission Management' },
            component: () => import('./permissions/CompanyPermissionsManage')
          }
        ]
      },
      {
        path: 'log',
        name: 'company-log',
        component: () => import('./CompanyLog')
      }
    ]
  }
];
