import Vue from 'vue';
import mutationFactory from '../../lib/mutationFactory';

export default {
  ...mutationFactory({
    singular: 'kiosk',
    plural: 'kiosks'
  }),

  SET_DEBUG: (state, debug) => {
    state.debug = {
      ...state.debug,
      ...debug
    };
  },

  SET_LOADING: (state, isLoading) => {
    state.loading = isLoading;
  },

  SET_ASSET_LOADING: (state, isLoading) => {
    state.assetLoading = isLoading;
  },

  SET_SLIDE_INDEX: (state, index) => {
    state.slideIndex = index;
  },

  GET_ASSET_REQUEST: state => {
    state.loading = true;
    state.loadingAction.getAsset = true;
    state.errorAction.getAsset = false;
  },
  GET_ASSET_SUCCESS: (state, body) => {
    state.loading = false;
    state.loadingAction.getAsset = false;
    state.errorAction.getAsset = false;

    state.asset = body;
  },
  GET_ASSET_ERROR: (state, error) => {
    state.loading = false;
    state.loadingAction.getAsset = false;
    state.errorAction.getAsset = error;
  },

  GET_ASSET_ACCOUNT_REQUEST: state => {
    state.loading = true;
    state.loadingAction.getAssetAccount = true;
    state.errorAction.getAssetAccount = false;
  },
  GET_ASSET_ACCOUNT_SUCCESS: (state, body) => {
    state.loading = false;
    state.loadingAction.getAssetAccount = false;
    state.errorAction.getAssetAccount = false;

    Vue.set(state.accounts, body._id, body);
  },
  GET_ASSET_ACCOUNT_ERROR: (state, error) => {
    state.loading = false;
    state.loadingAction.getAssetAccount = false;
    state.errorAction.getAssetAccount = error;
  },

  GET_NEWS_REQUEST: state => {
    state.loading = true;
    state.loadingAction.getNews = true;
    state.errorAction.getNews = false;
  },
  GET_NEWS_SUCCESS: (state, body) => {
    state.loading = false;
    state.loadingAction.getNews = false;
    state.errorAction.getNews = false;

    state.news = body;
  },
  GET_NEWS_ERROR: (state, error) => {
    state.loading = false;
    state.loadingAction.getNews = false;
    state.errorAction.getNews = error;
  },

  GET_ASSET_SMART_DATA_CONSUMPTION_REQUEST: state => {
    state.loading = true;
    state.loadingAction.getAssetSmartDataConsumption = true;
    state.errorAction.getAssetSmartDataConsumption = false;
  },
  GET_ASSET_SMART_DATA_CONSUMPTION_SUCCESS: (state, body) => {
    state.loading = false;
    state.loadingAction.getAssetSmartDataConsumption = false;
    state.errorAction.getAssetSmartDataConsumption = false;

    state.assetConsumption.smartData = body;
  },
  GET_ASSET_SMART_DATA_CONSUMPTION_ERROR: (state, error) => {
    state.loading = false;
    state.loadingAction.getAssetSmartDataConsumption = false;
    state.errorAction.getAssetSmartDataConsumption = error;
  },

  GET_ASSET_READING_CONSUMPTION_REQUEST: state => {
    state.loading = true;
    state.loadingAction.getAssetReadingConsumption = true;
    state.errorAction.getAssetReadingConsumption = false;
  },
  GET_ASSET_READING_CONSUMPTION_SUCCESS: (state, body) => {
    state.loading = false;
    state.loadingAction.getAssetReadingConsumption = false;
    state.errorAction.getAssetReadingConsumption = false;

    state.assetConsumption.reading = body;
  },
  GET_ASSET_READING_CONSUMPTION_ERROR: (state, error) => {
    state.loading = false;
    state.loadingAction.getAssetReadingConsumption = false;
    state.errorAction.getAssetReadingConsumption = error;
  },

  GET_ASSET_INVOICE_CONSUMPTION_REQUEST: state => {
    state.loading = true;
    state.loadingAction.getAssetInvoiceConsumption = true;
    state.errorAction.getAssetInvoiceConsumption = false;
  },
  GET_ASSET_INVOICE_CONSUMPTION_SUCCESS: (state, body) => {
    state.loading = false;
    state.loadingAction.getAssetInvoiceConsumption = false;
    state.errorAction.getAssetInvoiceConsumption = false;

    state.assetConsumption.invoice = body;
  },
  GET_ASSET_INVOICE_CONSUMPTION_ERROR: (state, error) => {
    state.loading = false;
    state.loadingAction.getAssetInvoiceConsumption = false;
    state.errorAction.getAssetInvoiceConsumption = error;
  },

  GET_ASSET_EMISSIONS_REQUEST: state => {
    state.loading = true;
    state.loadingAction.getAssetEmissions = true;
    state.errorAction.getAssetEmissions = false;
  },
  GET_ASSET_EMISSIONS_SUCCESS: (state, { key, body }) => {
    state.loading = false;
    state.loadingAction.getAssetEmissions = false;
    state.errorAction.getAssetEmissions = false;

    Vue.set(state.assetEmissions, key, body);
  },
  GET_ASSET_EMISSIONS_ERROR: (state, error) => {
    state.loading = false;
    state.loadingAction.getAssetEmissions = false;
    state.errorAction.getAssetEmissions = error;
  }
};
