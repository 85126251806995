import authGuard from '../../auth/authGuard';

export default [
  // Admin Views
  {
    path: '/admin',
    component: () => import('./AdminView'),
    beforeEnter: authGuard,
    children: [
      {
        path: '',
        name: 'admin-dashboard',
        component: () => import('./AdminDashboard'),
        beforeEnter: authGuard,
        meta: { permissions: ['admin'] }
      },
      {
        path: 'tools',
        name: 'admin-tools',
        component: () => import('./AdminTools'),
        beforeEnter: authGuard,
        meta: { permissions: ['admin'] },
        redirect: { name: 'admin-tools-general' },
        children: [
          {
            path: '',
            name: 'admin-tools-general',
            component: () => import('./tools/AdminToolsGeneral')
          },
          {
            path: 'asset',
            name: 'admin-tools-asset',
            component: () => import('./tools/AdminToolsAsset')
          },
          {
            path: 'websocket',
            name: 'admin-tools-websocket',
            component: () => import('./tools/AdminToolsWebsocket')
          }
        ]
      },
      {
        path: 'settings',
        name: 'admin-settings',
        component: () => import('./AdminSettings'),
        beforeEnter: authGuard,
        meta: { permissions: ['admin'] }
      },
      {
        path: 'log',
        name: 'admin-log',
        component: () => import('./AdminLog'),
        beforeEnter: authGuard,
        meta: { permissions: ['admin'] }
      },
      {
        path: 'emails',
        name: 'admin-emails',
        component: () => import('./AdminEmails'),
        beforeEnter: authGuard,
        meta: { permissions: ['admin'] }
      },
      {
        path: 'gridfetch',
        name: 'admin-gridfetch',
        redirect: { name: 'admin-gridfetch-loa' },
        component: () => import('./gridfetch/AdminGridfetchView'),
        beforeEnter: authGuard,
        meta: { permissions: ['admin'] },
        children: [
          {
            path: 'loa',
            name: 'admin-gridfetch-loa',
            component: () => import('./gridfetch/AdminGridfetchLOA')
          },
          {
            path: 'usage',
            name: 'admin-gridfetch-usage',
            component: () => import('./gridfetch/AdminGridfetchUsage')
          }
        ]
      }
    ]
  }
];
