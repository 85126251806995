import kioskGuard from '../../auth/kioskGuard';

export default [
  {
    path: 'asset',
    name: 'kiosk-asset-view',
    beforeEnter: kioskGuard(),
    component: () => import('./KioskAssetView')
  }
];
