<template>
  <div id="page-container" class="side-trans-enabled sidebar-dark" :class="{ 'sidebar-o-xs': mobileMenu, 'sidebar-o': menu }">
    <div data-simplebar="init"></div>
    <Navbar @onToggleMenu="onToggleMobileMenu" />
    <Header @onToggleMenu="onToggleMenu" />
    <main id="main-container">
      <router-view />
    </main>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import Header from '@/components/Header';
import Navbar from '@/components/Navbar';

export default {
  name: 'Main',
  components: {
    Header,
    Navbar
  },
  data() {
    return {
      menu: true,
      mobileMenu: false
    };
  },
  computed: {
    ...mapGetters({
      permissions: 'user/permissions'
    })
  },
  watch: {
    $route() {
      this.mobileMenu = false;
    }
  },
  mounted() {
    this.getPermissions();
  },
  methods: {
    ...mapActions({
      getPermissions: 'user/getPermissions'
    }),
    onToggleMenu() {
      this.menu = !this.menu;
      this.mobileMenu = true;
    },
    onToggleMobileMenu() {
      this.mobileMenu = !this.mobileMenu;
    }
  }
};
</script>
