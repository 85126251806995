import { getInstance } from './index';
import httpInterceptor from '../lib/httpInterceptors';

export default () => (to, from, next) => {
  const authService = getInstance();

  const fn = async () => {
    // userAgent is from kiosk v1
    // kioskAuth in URL is from kiosk v2

    const params = new URL(document.location).searchParams;
    const kioskAuth = params.get('kioskAuth');

    let type;
    let name;
    let id;
    let key;
    let secret;

    if (kioskAuth) {
      [type, name, id, key, secret] = kioskAuth.split('|');
    } else {
      [type, name, id, key, secret] = navigator.userAgent.split(' / ');
    }

    if (type !== 'Etainabl Kiosk' || !name || !id || !key || !secret) {
      return next({
        name: 'kiosk-error',
        query: { errorCode: 'INVALID_AUTH', errorData: `The auth string is invalid | kioskAuth: ${!!kioskAuth} | UA: ${!!navigator.userAgent}` }
      });
    }

    authService.loginWithKiosk({ key, secret, name, id });

    httpInterceptor(`${key}:${secret}`, authService, true);

    return next();
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch('loading', loading => {
    if (loading === false) {
      return fn();
    }

    return null;
  });

  return null;
};
