import Vue from 'vue';
import actionFactory from '../../lib/actionFactory';

export default {
  ...actionFactory({
    singular: 'kiosk',
    plural: 'kiosks'
  }),
  getAsset: async ({ commit }, { id }) => {
    try {
      commit('GET_ASSET_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/kiosks/${id}/asset`);
      commit('GET_ASSET_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_ASSET_ERROR', e);
      return e;
    }
  },
  getAssetAccount: async ({ commit }, { id, accountId }) => {
    try {
      commit('GET_ASSET_ACCOUNT_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/kiosks/${id}/asset/account/${accountId}`);
      commit('GET_ASSET_ACCOUNT_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_ASSET_ACCOUNT_ERROR', e);
      return e;
    }
  },
  getNews: async ({ commit }, { id }) => {
    try {
      commit('GET_NEWS_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/kiosks/${id}/news`);
      commit('GET_NEWS_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_NEWS_ERROR', e);
      return e;
    }
  },
  getAssetSmartDataConsumption: async ({ commit }, { id, params }) => {
    try {
      commit('GET_ASSET_SMART_DATA_CONSUMPTION_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/kiosks/${id}/asset/consumption?source=consumption`, { params });
      commit('GET_ASSET_SMART_DATA_CONSUMPTION_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_ASSET_SMART_DATA_CONSUMPTION_ERROR', e);
      return e;
    }
  },
  getAssetReadingConsumption: async ({ commit }, { id, params }) => {
    try {
      commit('GET_ASSET_READING_CONSUMPTION_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/kiosks/${id}/asset/consumption?source=reading`, { params });
      commit('GET_ASSET_READING_CONSUMPTION_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_ASSET_READING_CONSUMPTION_ERROR', e);
      return e;
    }
  },
  getAssetInvoiceConsumption: async ({ commit }, { id, params }) => {
    try {
      commit('GET_ASSET_INVOICE_CONSUMPTION_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/kiosks/${id}/asset/consumption?source=invoice`, { params });
      commit('GET_ASSET_INVOICE_CONSUMPTION_SUCCESS', body);
      return body;
    } catch (e) {
      commit('GET_ASSET_INVOICE_CONSUMPTION_ERROR', e);
      return e;
    }
  },
  getAssetEmissions: async ({ commit }, { id, key, params }) => {
    try {
      commit('GET_ASSET_EMISSIONS_REQUEST');
      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/kiosks/${id}/asset/emissions?source=invoice`, { params });
      commit('GET_ASSET_EMISSIONS_SUCCESS', { key, body });
      return body;
    } catch (e) {
      commit('GET_ASSET_EMISSIONS_ERROR', e);
      return e;
    }
  }
};
