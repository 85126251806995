import moment from 'moment';
import Vue from 'vue';

import helpers from './helpers';

export default {
  getAssets: async ({ commit }, { data } = { data: {} }) => {
    try {
      commit('GET_ASSETS_REQUEST', { data });

      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/assets`, data);
      commit('GET_ASSETS_SUCCESS', { body });
      return body;
    } catch (e) {
      commit('GET_ASSETS_ERROR', e);
      return e;
    }
  },
  getAssetGroups: async ({ commit }, { data } = { data: {} }) => {
    try {
      commit('GET_ASSET_GROUPS_REQUEST', { data });

      const { body } = await Vue.http.get(`${process.env.VUE_APP_API_URL}/asset-groups`, data);
      commit('GET_ASSET_GROUPS_SUCCESS', { body });
      return body;
    } catch (e) {
      commit('GET_ASSET_GROUPS_ERROR', e);
      return e;
    }
  },
  getAnalytics: async ({ commit }, { id, params }) => {
    try {
      const { body } = await Vue.http.get(`${process.env.VUE_APP_ANALYTICS_URL}`, {
        params,
        before: request => {
          commit('GET_ANALYTICS_REQUEST', { id, params, request });
        }
      });
      commit('GET_ANALYTICS_SUCCESS', { id, body });
      return body;
    } catch (e) {
      commit('GET_ANALYTICS_ERROR', e);
      return e;
    }
  },
  getAnalyticsV2: async ({ commit }, { id, params }) => {
    try {
      const { body } = await Vue.http.get(`${process.env.VUE_APP_ANALYTICS_URL}/v2`, {
        params,
        before: request => {
          commit('GET_ANALYTICS_REQUEST', { id, params, request });
        }
      });
      commit('GET_ANALYTICS_SUCCESS', { id, body });
      return body;
    } catch (e) {
      commit('GET_ANALYTICS_ERROR', e);
      return e;
    }
  },
  refreshGraphs: async ({ commit, dispatch, state }) => {
    try {
      commit('REFRESH_GRAPHS_START');

      // Abort any existing requests
      Object.entries(state.requests.getAnalytics).forEach(([key, req]) => {
        if (req.abort && typeof req.abort === 'function') {
          req.abort();
          console.log('Aborting...', key);
        }
      });

      if (state.options.page === 'emissions') {
        dispatch('getAssetEmissions');
        dispatch('getAssetEpcRatings');
        dispatch('getRenewablePercentage');
      } else if (state.options.page === 'consumption') {
        dispatch('getAssetDetailedConsumption');
        dispatch('getAssetConsumption');
      } else if (state.options.page === 'costs') {
        dispatch('getAssetCost');
      }

      commit('REFRESH_GRAPHS_END');
      return true;
    } catch (e) {
      commit('REFRESH_GRAPHS_ERROR', e);
      return e;
    }
  },
  getAssetDetailedConsumption: async ({ dispatch, state }) => {
    const [startDate, endDate] = state.options.dateRange;
    const { granularity, source } = state.options;

    const consumptionSummaryParams = {
      id: 'consumption',
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
      dataType: 'asset',
      granularity,
      source,
      detailedSource: true
    };

    if (state.options.type === 'portfolio') {
      consumptionSummaryParams.assetGroupId = state.options.selectedAssetGroupId;
      consumptionSummaryParams.dataType = 'asset-group';
    } else {
      consumptionSummaryParams.assetId = state.options.selectedAssetId;
    }

    const dateSubtractParams = helpers.getComparisonParams(startDate, endDate, state.options.comparePeriod);

    const prevStartDate = moment(startDate, endDate)
      .subtract(...dateSubtractParams)
      .format('YYYY-MM-DD');

    const prevEndDate = moment(endDate)
      .subtract(...dateSubtractParams)
      .format('YYYY-MM-DD');

    dispatch('getAnalyticsV2', {
      id: 'electricity-consumption',
      params: {
        accountType: 'electricity',
        ...consumptionSummaryParams
      }
    });
    dispatch('getAnalyticsV2', {
      id: 'electricity-consumption-prev',
      params: {
        accountType: 'electricity',
        ...consumptionSummaryParams,
        startDate: prevStartDate,
        endDate: prevEndDate
      }
    });
    dispatch('getAnalyticsV2', {
      id: 'solar-consumption',
      params: {
        accountType: 'solar',
        ...consumptionSummaryParams
      }
    });
    dispatch('getAnalyticsV2', {
      id: 'solar-consumption-prev',
      params: {
        accountType: 'solar',
        ...consumptionSummaryParams,
        startDate: prevStartDate,
        endDate: prevEndDate
      }
    });
    dispatch('getAnalyticsV2', {
      id: 'gas-consumption',
      params: {
        accountType: 'gas',
        ...consumptionSummaryParams
      }
    });
    dispatch('getAnalyticsV2', {
      id: 'gas-consumption-prev',
      params: {
        accountType: 'gas',
        ...consumptionSummaryParams,
        startDate: prevStartDate,
        endDate: prevEndDate
      }
    });
    dispatch('getAnalyticsV2', {
      id: 'water-consumption',
      params: {
        accountType: 'water',
        ...consumptionSummaryParams
      }
    });
    dispatch('getAnalyticsV2', {
      id: 'water-consumption-prev',
      params: {
        accountType: 'water',
        ...consumptionSummaryParams,
        startDate: prevStartDate,
        endDate: prevEndDate
      }
    });
  },
  getAssetEmissions: async ({ dispatch, state }) => {
    const [startDate, endDate] = state.options.dateRange;
    const { granularity, source } = state.options;

    const consumptionSummaryParams = {
      id: 'consumption',
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
      source,
      dataType: 'asset',
      granularity,
      emissions: true
    };

    if (state.options.type === 'portfolio') {
      consumptionSummaryParams.assetGroupId = state.options.selectedAssetGroupId;
    } else {
      consumptionSummaryParams.assetId = state.options.selectedAssetId;
    }

    const dateSubtractParams = helpers.getComparisonParams(startDate, endDate, state.options.comparePeriod);

    const prevStartDate = moment(startDate, endDate)
      .subtract(...dateSubtractParams)
      .format('YYYY-MM-DD');

    const prevEndDate = moment(endDate)
      .subtract(...dateSubtractParams)
      .format('YYYY-MM-DD');

    dispatch('getAnalyticsV2', {
      id: 'emissions-summary',
      params: {
        ...consumptionSummaryParams
      }
    });
    dispatch('getAnalyticsV2', {
      id: 'emissions-summary-prev',
      params: {
        ...consumptionSummaryParams,
        startDate: prevStartDate,
        endDate: prevEndDate
      }
    });
  },
  getAssetConsumption: async ({ dispatch, state }) => {
    const [startDate, endDate] = state.options.dateRange;
    const { granularity, source, accountType } = state.options;

    const consumptionSummaryParams = {
      id: 'consumption',
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
      dataType: 'asset',
      granularity,
      source
    };

    if (accountType) consumptionSummaryParams.accountType = accountType;

    if (state.options.type === 'portfolio') {
      consumptionSummaryParams.assetGroupId = state.options.selectedAssetGroupId;
      consumptionSummaryParams.dataType = 'asset-group';
    } else {
      consumptionSummaryParams.assetId = state.options.selectedAssetId;
    }

    const dateSubtractParams = helpers.getComparisonParams(startDate, endDate, state.options.comparePeriod);

    const prevStartDate = moment(startDate)
      .subtract(...dateSubtractParams)
      .format('YYYY-MM-DD');

    const prevEndDate = moment(endDate)
      .subtract(...dateSubtractParams)
      .format('YYYY-MM-DD');

    dispatch('getAnalyticsV2', {
      id: 'consumption',
      params: {
        detailedSource: true,
        ...consumptionSummaryParams
      }
    });

    dispatch('getAnalyticsV2', {
      id: 'consumption-prev',
      params: {
        ...consumptionSummaryParams,
        startDate: prevStartDate,
        endDate: prevEndDate
      }
    });
  },
  getAssetCost: async ({ dispatch, state }) => {
    const [startDate, endDate] = state.options.dateRange;

    const consumptionSummaryParams = {
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
      sourceData: 'invoice',
      dateBreakdown: 'monthly',
      includeCosts: true
    };

    if (state.options.type === 'portfolio') {
      consumptionSummaryParams.assetGroupId = state.options.selectedAssetGroupId;
    } else {
      consumptionSummaryParams.assetId = state.options.selectedAssetId;
    }

    const dateSubtractParams = helpers.getComparisonParams(startDate, endDate, state.options.comparePeriod);

    const prevStartDate = moment(startDate, endDate)
      .subtract(...dateSubtractParams)
      .format('YYYY-MM-DD');

    const prevEndDate = moment(endDate)
      .subtract(...dateSubtractParams)
      .format('YYYY-MM-DD');

    dispatch('getAnalytics', {
      id: 'electricity-consumption',
      params: {
        id: 'asset-consumption',
        accountType: 'electricity',
        estimateBreakdown: true,
        meterUserBreakdown: true,
        ...consumptionSummaryParams
      }
    });
    dispatch('getAnalytics', {
      id: 'electricity-consumption-prev',
      params: {
        id: 'asset-consumption',
        accountType: 'electricity',
        ...consumptionSummaryParams,
        startDate: prevStartDate,
        endDate: prevEndDate
      }
    });
    dispatch('getAnalytics', {
      id: 'gas-consumption',
      params: {
        id: 'asset-consumption',
        accountType: 'gas',
        estimateBreakdown: true,
        meterUserBreakdown: true,
        ...consumptionSummaryParams
      }
    });
    dispatch('getAnalytics', {
      id: 'gas-consumption-prev',
      params: {
        id: 'asset-consumption',
        accountType: 'gas',
        ...consumptionSummaryParams,
        startDate: prevStartDate,
        endDate: prevEndDate
      }
    });
    dispatch('getAnalytics', {
      id: 'water-consumption',
      params: {
        id: 'asset-consumption',
        accountType: 'water',
        estimateBreakdown: true,
        meterUserBreakdown: true,
        ...consumptionSummaryParams
      }
    });
    dispatch('getAnalytics', {
      id: 'water-consumption-prev',
      params: {
        id: 'asset-consumption',
        accountType: 'water',
        ...consumptionSummaryParams,
        startDate: prevStartDate,
        endDate: prevEndDate
      }
    });
  },

  getAssetEpcRatings: async ({ dispatch, state }) => {
    const params = {
      id: 'epc-ratings'
    };

    if (state.options.type === 'portfolio') {
      params.assetGroupId = state.options.selectedAssetGroupId;
    } else {
      params.assetId = state.options.selectedAssetId;
    }

    dispatch('getAnalytics', {
      id: 'epc-ratings',
      params
    });
  },

  getRenewablePercentage: async ({ dispatch, state }) => {
    const params = {
      id: 'asset-contract-renewable'
    };

    if (state.options.type === 'portfolio') {
      params.assetGroupId = state.options.selectedAssetGroupId;
    } else {
      params.assetId = state.options.selectedAssetId;
    }

    dispatch('getAnalytics', {
      id: 'asset-contract-renewable',
      params
    });
  }
};
