import Vue from 'vue';

import helpers from './helpers';

export default {
  GET_ASSETS_REQUEST: state => {
    state.loadingAction.getAssets = true;
    state.errorAction.getAssets = false;

    state.allAssets = [];
  },
  GET_ASSETS_SUCCESS: (state, { body }) => {
    state.loadingAction.getAssets = false;
    state.errorAction.getAssets = false;

    state.allAssets = body.data;
  },
  GET_ASSETS_ERROR: state => {
    state.loadingAction.getAssets = false;
    state.errorAction.getAssets = true;

    state.allAssets = [];
  },

  GET_ASSET_GROUPS_REQUEST: state => {
    state.loadingAction.getAssetGroups = true;
    state.errorAction.getAssetGroups = false;

    state.allAssetGroups = [];
  },
  GET_ASSET_GROUPS_SUCCESS: (state, { body }) => {
    state.loadingAction.getAssetGroups = false;
    state.errorAction.getAssetGroups = false;

    state.allAssetGroups = body.data;
  },
  GET_ASSET_GROUPS_ERROR: state => {
    state.loadingAction.getAssetGroups = false;
    state.errorAction.getAssetGroups = true;

    state.allAssetGroups = [];
  },

  GET_ANALYTICS_REQUEST: (state, { id, request }) => {
    if (state.requests.getAnalytics[id]) state.requests.getAnalytics[id].abort();

    Vue.set(state.loadingAction.getAnalytics, id, true);
    Vue.set(state.errorAction.getAnalytics, id, false);
    Vue.set(state.requests.getAnalytics, id, request);

    Vue.set(state.analytics, id, {});
  },
  GET_ANALYTICS_SUCCESS: (state, { id, body }) => {
    Vue.set(state.loadingAction.getAnalytics, id, false);
    Vue.set(state.errorAction.getAnalytics, id, false);
    Vue.set(state.requests.getAnalytics, id, false);

    Vue.set(state.analytics, id, body);
  },
  GET_ANALYTICS_ERROR: (state, { id, error }) => {
    Vue.set(state.loadingAction.getAnalytics, id, false);
    Vue.set(state.errorAction.getAnalytics, id, error);
    Vue.set(state.requests.getAnalytics, id, false);
  },

  SET_OPTION: (state, { key, option }) => {
    Vue.set(state.options, key, option);
  },

  UPDATE_COMPARISON_PERIODS: state => {
    const [startDate, endDate] = state.options.dateRange;

    state.comparePeriods = [
      { label: `Previous period [${helpers.getComparisonDates(startDate, endDate, 'period').join(' - ')}]`, value: 'period' },
      { label: `Previous month [${helpers.getComparisonDates(startDate, endDate, 'month').join(' - ')}]`, value: 'month' },
      { label: `Previous quarter [${helpers.getComparisonDates(startDate, endDate, 'quarter').join(' - ')}]`, value: 'quarter' },
      { label: `Previous year [${helpers.getComparisonDates(startDate, endDate, 'year').join(' - ')}]`, value: 'year' },
      { label: 'No comparison', value: null }
    ];
  },

  SELECT_ASSET: (state, id) => {
    state.options.selectedAssetId = id;
    state.options.selectedAssetGroupId = null;
  },

  SELECT_ASSET_GROUP: (state, id) => {
    state.options.selectedAssetGroupId = id;
    state.options.selectedAssetId = null;
  },

  REFRESH_GRAPHS_START: state => {
    state.loadingAction.refreshGraphs = true;
    state.errorAction.refreshGraphs = false;
  },

  REFRESH_GRAPHS_END: state => {
    state.loadingAction.refreshGraphs = false;
    state.errorAction.refreshGraphs = false;
  },

  REFRESH_GRAPHS_ERROR: state => {
    state.loadingAction.refreshGraphs = false;
    state.errorAction.refreshGraphs = true;
  }
};
