<template>
  <nav id="sidebar" aria-label="Main Navigation" data-cy="navbar">
    <div class="Navbar-logo">
      <router-link to="/">
        <div v-if="$auth && $auth.settings.showLogo" class="logo-wrapper secondary-logo">
          <div class="secondary-logo--image">
            <img :src="$auth.settings.logoUrl" alt="Company Logo" />
          </div>
          <div>
            <div class="secondary-logo--text">{{ $t('POWERED_BY') }}</div>
            <Logo :white="true" />
          </div>
        </div>
        <div v-else class="logo-wrapper">
          <Logo :white="true" />
        </div>
      </router-link>
      <button type="button" class="btn btn-outline-primary d-lg-none" @click="onClickMenu">
        <i class="fa fa-fw fa-times"></i>
      </button>
    </div>
    <div :class="{ 'is-secondary-logo': $auth && $auth.settings.showLogo }" class="js-sidebar-scroll">
      <div class="content-side">
        <ul v-for="(items, key) in navItemsBySection" :key="key" class="nav-main mb-1">
          <li class="nav-main-heading">{{ key.toUpperCase() }}</li>

          <li v-for="item in items" :key="item.name" class="nav-main-item" @click="onToggleMenu('')">
            <router-link
              active-class="active"
              exact-path
              data-cy="dashboard"
              :to="{ name: item.route }"
              class="nav-main-link"
              :class="{ disabled: item.disabled }"
            >
              <i class="nav-main-link-icon fa" :class="item.icon"></i>
              <span class="nav-main-link-name">{{ item.name }} <span v-if="item.isBeta" class="badge badge-info">BETA</span></span>
            </router-link>
          </li>
        </ul>

        <!-- <ul class="nav-main">
          <li class="nav-main-item" @click="onToggleMenu('')">
            <router-link active-class="active" exact-path data-cy="dashboard" to="/" class="nav-main-link">
              <i class="nav-main-link-icon fa fa-gauge"></i>
              <span class="nav-main-link-name">{{ $t('DASHBOARD') }}</span>
            </router-link>
          </li>

          <li class="nav-main-item" @click="onToggleMenu('')">
            <a class="nav-main-link disabled">
              <i class="nav-main-link-icon fa fa-inbox"></i>
              <span class="nav-main-link-name">{{ $t('INBOX') }}</span>
            </a>
          </li>

          <li class="nav-main-heading">{{ $t('MANAGEMENT') }}</li>

          <li class="nav-main-item" @click="onToggleMenu('')">
            <router-link active-class="active" data-cy="assets-view-all" to="/assets" class="nav-main-link">
              <i class="nav-main-link-icon fa fa-buildings"></i>
              <span class="nav-main-link-name">{{ $tc('SITE', 2) }}</span>
            </router-link>
          </li>

          <li class="nav-main-item" @click="onToggleMenu('')">
            <router-link active-class="active" data-cy="portfolios-view-all" to="/portfolios/manage" class="nav-main-link">
              <i class="nav-main-link-icon fa fa-layer-group"></i>
              <span class="nav-main-link-name">{{ $tc('PORTFOLIO', 2) }}</span>
            </router-link>
          </li>

          <li class="nav-main-item" @click="onToggleMenu('')">
            <router-link active-class="active" data-cy="entities-view-all" to="/entities" class="nav-main-link">
              <i class="nav-main-link-icon fa fa-sitemap"></i>
              <span class="nav-main-link-name">{{ $tc('ENTITY', 2) }}</span>
            </router-link>
          </li>

          <li class="nav-main-heading">Tools</li>

          <li
            v-if="$auth.isAdmin || $auth.roles.includes('company_owner') || $auth.roles.includes('company_user_full')"
            class="nav-main-item"
            @click="onToggleMenu('')"
          >
            <router-link active-class="active" data-cy="invoices-upload" to="/invoices/upload" class="nav-main-link">
              <i class="nav-main-link-icon fa fa-file-invoice"></i>
              <span class="nav-main-link-name">Bill Validation</span>
            </router-link>
          </li>

          <li class="nav-main-item" @click="onToggleMenu('')">
            <router-link active-class="active" data-cy="invoices-upload" to="/data-coverage" class="nav-main-link disabled">
              <i class="nav-main-link-icon fa fa-list-check"></i>
              <span class="nav-main-link-name">Data Coverage</span>
            </router-link>
          </li>

          <li class="nav-main-item" @click="onToggleMenu('')">
            <router-link active-class="active" data-cy="invoices-upload" to="/targets" class="nav-main-link disabled">
              <i class="nav-main-link-icon fa fa-bullseye"></i>
              <span class="nav-main-link-name">Targets</span>
            </router-link>
          </li>

          <li class="nav-main-item" @click="onToggleMenu('')">
            <router-link active-class="active" data-cy="invoices-upload" to="/targets" class="nav-main-link disabled">
              <i class="nav-main-link-icon fa fa-tree-city"></i>
              <span class="nav-main-link-name">GRESB</span>
            </router-link>
          </li>

          <li v-if="$auth.roles.includes('company_user') || $auth.roles.includes('company_owner')" class="nav-main-item" @click="onToggleMenu('')">
            <router-link active-class="active" :to="`/imports`" class="nav-main-link">
              <i class="nav-main-link-icon fa fa-file-import"></i>
              <span class="nav-main-link-name"> {{ $tc('IMPORT', 2) }}</span>
            </router-link>
          </li>

          <li class="nav-main-heading">Automation</li>

          <li class="nav-main-item" @click="onToggleMenu('')">
            <router-link active-class="active" data-cy="invoices-upload" to="/connectors" class="nav-main-link">
              <i class="nav-main-link-icon fa fa-plug"></i>
              <span class="nav-main-link-name">Connectors</span>
            </router-link>
          </li>

          <li class="nav-main-item" @click="onToggleMenu('')">
            <router-link active-class="active" data-cy="automation" to="/automation" class="nav-main-link">
              <i class="nav-main-link-icon fa fa-circle-nodes"></i>
              <span class="nav-main-link-name">Integrations</span>
            </router-link>
          </li>

          <li class="nav-main-heading">Insights</li>

          <li class="nav-main-item" :class="{ open: openMenu === 'Analytics' }">
            <a class="nav-main-link nav-main-link-submenu" data-cy="portfolio" href="#" @click.prevent="onToggleMenu('Analytics')">
              <i class="nav-main-link-icon fa fa-chart-mixed"></i>
              <span class="nav-main-link-name">{{ $tc('ANALYTIC', 2) }}</span>
            </a>
            <ul class="nav-main-submenu">
              <li class="nav-main-item">
                <router-link
                  active-class="active"
                  class="nav-main-link"
                  data-cy="analytics"
                  :to="{ name: 'analytics-emissions', query: $route.query }"
                >
                  <i class="nav-main-link-icon fa fa-smog"></i>
                  <span class="nav-main-link-name">{{ $tc('EMISSION', 2) }}</span>
                </router-link>
              </li>
              <li class="nav-main-item">
                <router-link
                  active-class="active"
                  class="nav-main-link"
                  data-cy="analytics"
                  :to="{ name: 'analytics-consumption', query: $route.query }"
                >
                  <i class="nav-main-link-icon fa fa-plug"></i>
                  <span class="nav-main-link-name">{{ $tc('CONSUMPTION', 2) }}</span>
                </router-link>
              </li>
              <li class="nav-main-item">
                <router-link active-class="active" class="nav-main-link" data-cy="analytics" :to="{ name: 'analytics-costs', query: $route.query }">
                  <i class="nav-main-link-icon fa fa-coins"></i>
                  <span class="nav-main-link-name">{{ $tc('COST', 2) }}</span>
                </router-link>
              </li>
            </ul>
          </li>

          <li class="nav-main-item" @click="onToggleMenu('')">
            <router-link active-class="active" to="/benchmarks" class="nav-main-link">
              <i class="nav-main-link-icon fa fa-chart-column"></i>
              <span class="nav-main-link-name">Benchmarks</span>
            </router-link>
          </li>

          <li class="nav-main-item" @click="onToggleMenu('')">
            <router-link active-class="active" to="/reports" class="nav-main-link">
              <i class="nav-main-link-icon fa fa-file-chart-pie"></i>
              <span class="nav-main-link-name">Reports <span class="badge badge-info">BETA</span></span>
            </router-link>
          </li>

          <li class="nav-main-heading">{{ $t('MARKETPLACE') }}</li>

          <li class="nav-main-item" @click="onToggleMenu('')">
            <router-link active-class="active" to="/marketplace/carbon" class="nav-main-link">
              <i class="nav-main-link-icon fa fa-trees"></i>
              <span class="nav-main-link-name"> {{ $tc('CARBON_PROJECT', 2) }}</span>
            </router-link>
          </li>

          <div v-if="$auth.isAdmin || $auth.roles.includes('company_owner')">
            <li class="nav-main-heading">{{ $t('COMPANY_ADMIN') }}</li>

            <li class="nav-main-item" @click="onToggleMenu('')">
              <router-link active-class="active" :to="`/companies/${$auth.companyId}/users`" class="nav-main-link">
                <i class="nav-main-link-icon fa fa-users"></i>
                <span class="nav-main-link-name"> {{ $tc('USER', 2) }}</span>
              </router-link>
            </li>

            <li class="nav-main-item" @click="onToggleMenu('')">
              <router-link active-class="active" :to="`/companies/${$auth.companyId}`" exact class="nav-main-link">
                <i class="nav-main-link-icon fa fa-cog"></i>
                <span class="nav-main-link-name"> {{ $tc('SETTING', 2) }}</span>
              </router-link>
            </li>

            <li class="nav-main-item" @click="onToggleMenu('')">
              <router-link active-class="active" :to="`/companies/${$auth.companyId}/log`" class="nav-main-link">
                <i class="nav-main-link-icon fa fa-monitor-waveform"></i>
                <span class="nav-main-link-name"> {{ $t('ACTIVITY_LOG') }}</span>
              </router-link>
            </li>
          </div>

          <div v-if="$auth.isAdmin">
            <li class="nav-main-heading">{{ $t('ADMIN') }}</li>

            <li class="nav-main-item" @click="onToggleMenu('')">
              <router-link active-class="active" to="/admin" class="nav-main-link">
                <i class="nav-main-link-icon fa fa-user-shield"></i>
                <span class="nav-main-link-name"> {{ $t('OVERVIEW') }}</span>
              </router-link>
            </li>

            <li class="nav-main-item" :class="{ open: openMenu === 'Companies_admin' }">
              <a class="nav-main-link nav-main-link-submenu" href="#" @click.prevent="onToggleMenu('Companies_admin')">
                <i class="nav-main-link-icon fa fa-users"></i>
                <span class="nav-main-link-name">{{ $tc('COMPANY', 2) }}</span>
              </a>
              <ul class="nav-main-submenu">
                <li class="nav-main-item">
                  <router-link active-class="active" class="nav-main-link" to="/companies">
                    <i class="nav-main-link-icon fa fa-list"></i>
                    <span class="nav-main-link-name">{{ $t('VIEW_ALL') }}</span>
                  </router-link>
                </li>
                <li class="nav-main-item">
                  <router-link active-class="active" class="nav-main-link" to="/companies/create">
                    <i class="nav-main-link-icon fa fa-plus"></i>
                    <span class="nav-main-link-name">{{ $t('CREATE_NEW') }}</span>
                  </router-link>
                </li>
              </ul>
            </li>

            <li class="nav-main-item" :class="{ open: openMenu === 'Assets_admin' }">
              <a class="nav-main-link nav-main-link-submenu" href="#" @click.prevent="onToggleMenu('Assets_admin')">
                <i class="nav-main-link-icon fa fa-building"></i>
                <span class="nav-main-link-name">{{ $tc('ASSET', 2) }}</span>
              </a>
              <ul class="nav-main-submenu">
                <li class="nav-main-item">
                  <router-link active-class="active" class="nav-main-link" to="/admin/assets">
                    <i class="nav-main-link-icon fa fa-list"></i>
                    <span class="nav-main-link-name">{{ $t('VIEW_ALL') }}</span>
                  </router-link>
                </li>
                <li class="nav-main-item">
                  <router-link active-class="active" class="nav-main-link" to="/asset/wizard">
                    <i class="nav-main-link-icon fa fa-plus"></i>
                    <span class="nav-main-link-name">{{ $t('CREATE_NEW') }}</span>
                  </router-link>
                </li>
              </ul>
            </li>

            <li v-if="!$auth.isEntityGroup" class="nav-main-item" :class="{ open: openMenu === 'Invoices' }">
              <a class="nav-main-link nav-main-link-submenu" href="#" data-cy="invoices" @click.prevent="onToggleMenu('Invoices')">
                <i class="nav-main-link-icon fa fa-file-invoice"></i>
                <span class="nav-main-link-name">{{ $tc('INVOICE', 2) }}</span>
              </a>
              <ul class="nav-main-submenu">
                <li class="nav-main-item">
                  <router-link active-class="active" class="nav-main-link" to="/invoices" data-cy="invoices-view-all">
                    <i class="nav-main-link-icon fa fa-list"></i>
                    <span class="nav-main-link-name">{{ $t('VIEW_ALL') }}</span>
                  </router-link>
                </li>
                <li class="nav-main-item">
                  <router-link active-class="active" class="nav-main-link" to="/invoices/upload" data-cy="invoices-uploader">
                    <i class="nav-main-link-icon fa fa-upload"></i>
                    <span class="nav-main-link-name">{{ $t('UPLOADER') }}</span>
                  </router-link>
                </li>
              </ul>
            </li>

            <li class="nav-main-item" :class="{ open: openMenu === 'Entities_admin' }">
              <a class="nav-main-link nav-main-link-submenu" data-toggle="submenu" href="#" @click.prevent="onToggleMenu('Entities_admin')">
                <i class="nav-main-link-icon fa fa-briefcase"></i>
                <span class="nav-main-link-name">{{ $tc('ENTITY', 2) }}</span>
              </a>
              <ul class="nav-main-submenu">
                <li class="nav-main-item">
                  <router-link active-class="active" class="nav-main-link" to="/entities">
                    <i class="nav-main-link-icon fa fa-list"></i>
                    <span class="nav-main-link-name">{{ $t('VIEW_ALL') }}</span>
                  </router-link>
                </li>
                <li class="nav-main-item">
                  <router-link active-class="active" class="nav-main-link" to="/new-entity">
                    <i class="nav-main-link-icon fa fa-plus"></i>
                    <span class="nav-main-link-name">{{ $t('CREATE_NEW') }}</span>
                  </router-link>
                </li>
              </ul>
            </li>

            <li class="nav-main-item" :class="{ open: openMenu === 'Suppliers_admin' }">
              <a class="nav-main-link nav-main-link-submenu" href="#" @click.prevent="onToggleMenu('Suppliers_admin')">
                <i class="nav-main-link-icon fa fa-box"></i>
                <span class="nav-main-link-name">{{ $tc('SUPPLIER', 2) }}</span>
              </a>
              <ul class="nav-main-submenu">
                <li class="nav-main-item">
                  <router-link active-class="active" class="nav-main-link" to="/suppliers">
                    <i class="nav-main-link-icon fa fa-list"></i>
                    <span class="nav-main-link-name">{{ $t('VIEW_ALL') }}</span>
                  </router-link>
                </li>
                <li class="nav-main-item">
                  <router-link active-class="active" class="nav-main-link" to="/suppliers/create">
                    <i class="nav-main-link-icon fa fa-plus"></i>
                    <span class="nav-main-link-name">{{ $t('CREATE_NEW') }}</span>
                  </router-link>
                </li>
              </ul>
            </li>
          </div>
        </ul> -->
      </div>
    </div>
  </nav>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Logo from './Logo';

export default {
  name: 'Navbar',
  components: {
    Logo
  },
  data() {
    return {
      openMenu: '',
      openSubMenu: '',
      navItems: []
    };
  },
  computed: {
    ...mapGetters({
      portfolios: 'assetGroup/assetGroups',
      permissions: 'user/permissions'
    }),
    navItemsBySection() {
      return this.navItems.reduce((acc, item) => {
        if (item.hide) {
          return acc;
        }

        if (!acc[item.section]) acc[item.section] = [];

        if (!item.hide) acc[item.section].push(item);

        return acc;
      }, {});
    }
  },
  mounted() {
    this.navItems = [
      {
        name: 'Dashboard',
        section: '',
        icon: 'fa-gauge',
        route: 'Home',
        hide: !this.$permissions.routeName('Home')
      },
      {
        name: 'Sites',
        section: 'management',
        icon: 'fa-buildings',
        route: 'asset-list',
        hide: !this.$permissions.routeName('asset-list')
      },
      {
        name: 'Portfolios',
        section: 'management',
        icon: 'fa-layer-group',
        route: 'portfolio-management',
        hide: !this.$permissions.routeName('portfolio-management')
      },
      {
        name: 'Entities',
        section: 'management',
        icon: 'fa-sitemap',
        route: 'entity-list',
        hide: !this.$permissions.routeName('entity-list')
      },
      {
        name: 'Import Data',
        section: 'management',
        icon: 'fa-file-import',
        route: 'import-list',
        hide: !this.$permissions.routeName('import-list')
      },
      {
        name: 'Bill Validation',
        section: 'tools',
        icon: 'fa-file-invoice',
        route: 'invoice-upload',
        hide: !this.$permissions.routeName('invoice-upload')
      },
      {
        name: 'Data Coverage',
        section: 'tools',
        disabled: true,
        icon: 'fa-list-check',
        route: 'data-coverage-dashboard',
        hide: !this.$permissions.routeName('data-coverage-dashboard')
      },
      {
        name: 'Targets',
        section: 'tools',
        disabled: true,
        icon: 'fa-bullseye',
        route: 'targets-dashboard',
        hide: !this.$permissions.routeName('targets-dashboard')
      },
      // {
      //   name: 'GRESB',
      //   section: 'tools',
      //   disabled: true,
      //   icon: 'fa-tree-city',
      //   route: '/gresb',
      //   excludePermission: ['meter_user']
      // },
      {
        name: 'Consumption',
        section: 'insights',
        icon: 'fa-chart-mixed',
        route: 'analytics-consumption',
        hide: !this.$permissions.routeName('analytics-consumption')
      },
      {
        name: 'Emissions',
        section: 'insights',
        icon: 'fa-smog',
        route: 'analytics-emissions',
        hide: !this.$permissions.routeName('analytics-emissions')
      },
      {
        name: 'Costs',
        section: 'insights',
        icon: 'fa-coins',
        route: 'analytics-costs',
        hide: !this.$permissions.routeName('analytics-costs')
      },
      {
        name: 'Benchmarks',
        section: 'insights',
        icon: 'fa-chart-column',
        route: 'benchmarks',
        hide: !this.$permissions.routeName('benchmarks')
      },
      {
        name: 'Reports',
        section: 'insights',
        isBeta: true,
        icon: 'fa-file-chart-pie',
        route: 'reports-list',
        hide: !this.$permissions.routeName('reports-list')
      },
      {
        name: 'Connectors',
        section: 'automation',
        icon: 'fa-plug',
        route: 'connectors',
        hide: !this.$permissions.routeName('connectors')
      },
      {
        name: 'Integrations',
        section: 'automation',
        icon: 'fa-circle-nodes',
        route: 'automation-list',
        hide: !this.$permissions.routeName('automation-list')
      },
      // {
      //   name: 'Carbon Project',
      //   section: 'marketplace',
      //   icon: 'fa-trees',
      //   route: '/marketplace/carbon',
      //   excludePermission: ['meter_user']
      // },
      {
        name: 'Users',
        section: 'company admin',
        icon: 'fa-users',
        route: 'company-users-list',
        hide: !this.$permissions.routeName('company-users-list')
      },
      {
        name: 'Settings',
        section: 'company admin',
        icon: 'fa-cog',
        route: 'company-view',
        hide: !this.$permissions.routeName('company-view')
      },
      {
        name: 'Activity Log',
        section: 'company admin',
        icon: 'fa-monitor-waveform',
        route: 'company-log',
        hide: !this.$permissions.routeName('company-log')
      },
      {
        name: 'Admin Overview',
        section: 'admin',
        icon: 'fa-user-shield',
        route: 'admin-dashboard',
        hide: !this.$permissions.routeName('admin-dashboard')
      },
      {
        name: 'Suppliers',
        section: 'admin',
        icon: 'fa-box',
        route: 'supplier-list',
        hide: !this.$permissions.routeName('supplier-list')
      }
    ];
  },
  methods: {
    ...mapActions({
      listAssetGroups: 'assetGroup/list'
    }),
    onToggleMenu(key) {
      this.openMenu = this.openMenu === key ? '' : key;

      if (key === 'Portfolio') {
        this.listAssetGroups();
      }
    },
    onToggleSubMenu(key) {
      this.openSubMenu = this.openSubMenu === key ? '' : key;
    },
    onClickMenu() {
      this.$emit('onToggleMenu');
    }
  }
};
</script>
<style lang="scss" scoped>
.Navbar-logo {
  padding: 0 1.5rem;
}

.logo-wrapper {
  margin: 1rem 0 0.75rem 0;
}

.logo-wrapper.secondary-logo {
  margin: 1.25rem 0 0 0;
}

.secondary-logo {
  margin-left: 0.75rem;

  &--image {
    text-align: center;
    margin-bottom: 1rem;

    img {
      max-width: 100%;
      max-height: 100px;
    }
  }

  &--text {
    font-size: 9px;
    color: #aaa;
    margin-bottom: -4px;
    margin-left: -1px;
    font-weight: 600;
  }

  .Logo {
    width: 80px;
  }
}

.nav-main-link.disabled {
  cursor: pointer;
  color: #3b5566 !important;
  pointer-events: none;
}
</style>
